.karmen {
    --_g: 10% /45% 45% no-repeat linear-gradient(#000 0 0);
    --m:
      left   var(--_i,0%) top    var(--_g),
      bottom var(--_i,0%) left   var(--_g),
      top    var(--_i,0%) right  var(--_g),
      right  var(--_i,0%) bottom var(--_g);
    -webkit-mask: var(--m);
            mask: var(--m);
    transition: .7s linear;
    cursor: pointer;
    box-shadow: -1px 2px 9px 5px rgba(255,255,255,0.75);
    -webkit-box-shadow: -1px 2px 9px 5px rgba(24, 14, 14, 0.75);
    -moz-box-shadow: -1px 2px 9px 5px rgba(255,255,255,0.75);
    position: relative;
    top:20px;
    width:90%;
    /* transform: scale(1.2); */
  }
  .karmen:hover {
    --_i: 10%;
    filter: grayscale(0);
  }

  .festival-container {
    
    background-image: url('../images/karmen/karmen9.jpg'); 
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    filter: brightness(100%);
  }


.festival-title{
    /* font-size: xx-large; */
    border-bottom: 3px solid rgb(255, 255, 255);
    width: 80%;
}

.festival-text{
    line-height: 39px;
}


