

.participants-main-container{
    background-image: url('../images/senetvarisleri/47.jpeg'); 
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  filter: brightness(100%);
}
.participant-item{
    transition: all 1s;
}

.participant-item:hover{
    transform: scale(1.05);
}

.scaling{
    transition: all .8s;
    animation: scale 1s infinite alternate; 
}
.diger:hover{
    -webkit-box-shadow: 0px 3px 47px 8px rgba(255,255,255,1);
-moz-box-shadow: 0px 3px 47px 8px rgba(255,255,255,1);
box-shadow: 0px 3px 47px 8px rgba(255,255,255,1);
transform: scale(1.06);
}


.daha{
    color: rgb(220, 215, 215);
    text-decoration: underline;
}


.participant-img{
    border-radius: 10px;
}

@keyframes scale {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}