.photos-container{
    background-image: url('../images/final/66.JPG'); 
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

}


.photo {
  transition: all 0.3s ease;
  z-index: 1;


}

.photo:hover {
        scale: 1.1;
}
