.mediaItem{
    /* background: rgb(153,58,58); */
/* background: linear-gradient(135deg, rgba(153,58,58,1) 0%, rgba(205,200,194,1) 9%, rgba(52,43,32,1) 98%, rgba(24,14,2,1) 100%); */
height: 100%;
width: 80%;
border-radius: 10px;
margin-left: 40px;
}

.responsive-slider {
    width: 70%;
    height: auto;
  }

.media-container{
    padding: 10px;
     display: flex;
     justify-content: center;
    align-items: center;
    }

.media-img{
    width: 100%;
    height: 60%;
    box-shadow: -1px 2px 9px 5px rgba(255,255,255,0.75);
-webkit-box-shadow: -1px 2px 9px 5px rgba(255,255,255,0.75);
-moz-box-shadow: -1px 2px 9px 5px rgba(255,255,255,0.75);
transition: all .3s ease-in-out;
margin-bottom: 5px;
border-radius: 10px;
}

.media-img:hover{
    transform: scale(1.04);
}

.media-date{
    font-style: italic;
    font-size: smaller;
}

.media-title{
    font-weight: 700;
}

/* HOme page end */


/* Media Page */

.media-page-img{
    width: 100%;
    height: 50%;
}


.media-items-container{
    width: 100%; /* Adjust width to 100% */
    min-height: 100vh; /* Adjust height to fit the viewport */
    overflow-y: auto; 
}

.media-page-item{
    height: 240px; /* Allow height to be flexible */
    /* min-height: 200px;  */
}
.media-page{
    
    background-image: url('../images/final/1.JPG'); 
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll; /* Use scroll instead of fixed on mobile */
    padding: 50px 20px; /* Add responsive padding */
    display: flex;
    flex-direction: column; /* Ensure vertical alignment */
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1170px){
    .media-page {
        height: 1330px; /* Let height adjust based on content */
    }
    .media-page-item {
        min-height: 280px;
    }
  }

  @media screen and (max-width: 800px){
    .media-page{
        height: 1300px;
      }
  }
  @media screen and (max-width: 768px){
    .media-page{
        height: 1600px;
      }
  }

  @media screen and (max-width: 641px){
    .media-page{
        height: 3200px;
      }
      .media-page-item{
        /* height: 300px; */
    }
  }
/* Media Page end */