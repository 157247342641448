


.footer{
width: 100vw;
 height: 290px;
background-color:var(--primary);
color: rgb(255, 255, 255);
display: flex;
flex-direction: column;
overflow-x: hidden;
background: rgb(153,58,58);
background: linear-gradient(135deg, rgba(153,58,58,1) 0%, rgba(93,20,20,1) 45%, rgba(205,200,194,1) 50%, rgba(24,14,2,1) 55%);

}


.footer-logo{
    margin: 20px;
    width: 240px;
    height: 110px;
}


.footer-info{
    margin: 10px 0;
}

.footer-up{
    display: flex;
    justify-content: space-between;
}







.footer-icon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 600px){
    .rights-info{
        font-size: 16px;
    }
}
@media screen and (max-width: 490px)
{

    .footer-logo{
        margin: 20px;
        width: 200px;
        height: 90px;
    }
    .footer-info{
        font-size: 15px;
        align-items: center;
    }
    .rights-info{
        font-size: 13px;
    }
}


@media screen and (max-width: 420px){
    .footer-logo{
        margin: 20px;
        width: 160px;
        height: 70px;
    }
    .footer-info{
        font-size: 12px;
        align-items: center;
    }
    .rights-info{
        font-size: 10px;
    }
}

@media screen and (max-width: 380px){
    .rights-info{
        font-size: 10px;
    }

    .footer{
        height: 325px;
    }
}

@media screen and (max-width: 345px){

    .footer-info{
        font-size: 9px;
        align-items: center;
    }
}